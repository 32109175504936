<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Razzzila Hub
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Make your projects management easier
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            method="post"
            @submit.prevent="register"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              class="mb-3"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="password_confirmation"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password Confirmation"
              placeholder="············"
              hide-details
            ></v-text-field>

            <vue-recaptcha
              :theme="$vuetify.theme.dark ? 'dark' : 'light'"
              ref="recaptcha"
              class="mt-3"
              :sitekey="recaptcha"
              :load-recaptcha-script="true"
              type="invisible"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
            >
            </vue-recaptcha>

            <v-checkbox
              hide-details
              class="mt-1"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
            >
              Sign Up
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Sign in instead
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social link -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
            @click="authProvider(link.provider)"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiEyeOutline, mdiEyeOffOutline, mdiGoogle} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data() {
    return {
      recaptcha: process.env.VUE_APP_RECAPTCHA_KEY,

      isPasswordVisible: false,

      // username,
      email: '',
      password: '',
      password_confirmation: '',
      socialLink: [{
        provider: 'google',
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      }],

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      recaptchaGKey: '',
    }
  },
  methods: {
    authProvider(provider) {
      this.$auth.oauth2(provider, {})
    },

    async register() {
      const app = this
      try {
        await this.$auth.register({
          data: {
            email: app.email,
            password: app.password,
            recaptcha: app.recaptchaGKey,
            password_confirmation: app.password_confirmation,
          },
          autoLogin: true,
          fetchUser: true,
          success() {
            app.success = true
            app.$store.dispatch('addAlertMsg', {text: 'Registered successfully'})
            // this.$router.push({ name: 'login', params: { successRegistrationRedirect: true } })
            app.onCaptchaExpired()
          },
          error(res) {
            console.log('error', res)
            app.has_error = true
            app.error = res.response.data.error
            app.errors = res.response.data.errors || {}

            res.response.data.errors.map(e => {
              app.$store.dispatch('addAlertMsg', {
                type: 'error',
                text: e
              })
            })
          }
        })
      } catch (e) {
        let errMsg = 'Something gone wrong'

        if (e.response.data.errors) {
          Object.values(e.response.data.errors).map(async e => {
            await app.$store.dispatch('addAlertMsg', {
              text: e[0],
              type: 'error'
            })
          })
        } else {
          await app.$store.dispatch('addAlertMsg', {
            text: errMsg,
            type: 'error'
          })
        }
      }
    },
    onCaptchaVerified(recaptchaToken) {
      this.recaptchaGKey = recaptchaToken
      this.validateCaptcha = true
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
